*{
  scrollbar-width: none;
  line-height: 25px;
}
p{
  line-height: 22px;
}


h2,h3,h4,h5{
  color: #156bb4;
  line-height: 25px;
}

.header-h1{
  margin: 0;
  line-height: 45px;
  animation: move 0.5s ease-in-out;
  font-size: 40px;
  color: #fff;

}

@keyframes move {
  0% {
    transform: translateX(7%);
    opacity: 0;
  }
  70% {
    transform: translateX(-1%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}

.header-p{
  margin: 20px 0 0 0;
  animation: move 1s ease-in-out;
  color: #fff;
}

.header-img{
  width: 90%;
  animation: moveimg 1s ease-in-out;
}

@keyframes moveimg {
  0% {
    transform: translateX(-7%);
    opacity: 0;
  }
  70% {
    transform: translateX(1%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}

.logo-img{
  height: 75px;
  animation: moveimg 1s ease-in-out;
}



body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  background: linear-gradient(
      to right, 
      #fcedff,  
      #c0e5f9,  
      #dff3c0,  
      #fff2d7   
    );
    /* background-image: url('https://s3.ap-south-1.amazonaws.com/awsimages.imagesbazaar.com/1200x1800-new/13841/ES498848.jpg'); */
    height: 100vh;
    /* color: #00290430; */

}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  background: #fff;
  z-index: 5;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}
.navbar .logo {
  font-size: 1.5em;
  font-weight: 700;
}
.navbar .menu {
  display: flex;
  align-items: center;
}
.navbar .menu a {
  text-decoration: none;
  color: #000;
  margin: 0 15px;
  font-size: 1em;
  animation: ania 1s ease-in-out;
}
.navbar .menu a:hover {
  color: #007BFF;
}
.navbar .menu .btn {
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  color: #000000;
  border: 2px solid #000;
  transition: 0.3s ease-in-out;
  margin-left: 15px;
}
.navbar .menu .btn:hover{
  color: #ffffff;
  background: #000;
}

.btn {
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  color: #000000;
  border: 2px solid #000;
  transition: 0.3s ease-in-out;
  margin-left: 15px;
}

.btn:hover{
  color: #ffffff;
  background: #000;
}


@keyframes ania {
  0% {
    transform: translateX(10%);
    opacity: 0;
  }
  70% {
    transform: translateX(-1%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}

.App{
  scrollbar-width: none;
  background:#fffbf4;

}
.Mobile{
  display: none;
}
.zoomIn:hover{
  transform: scale(1.1) !important;
}
.zoom{
  transition: all .2s ease-in-out;
}
.zoom:hover{
  transform: scale(1.02) !important;
}

.button:hover{
  transition: 0.1s ease-in-out ;
  background: #dc143c !important;
  border: 1px solid #dc143c !important;
  color: white !important;
}

.link{
  text-decoration: none;
  color:rgb(0, 0, 0) ;
}
.nav{
  text-decoration: none;
  color:rgb(0, 0, 0) ;
}
.nav:hover{
  transition: transform 0.1s;
  transform: scale(1.02);
  color:#007BFF !important ;
}
.active{
  transform: scale(1.02);
  color:#007BFF !important ;
}

.socialmedia div:hover{
background: #156bb4;
color: white !important;
}

.card:hover .card-content{
  display: block !important; 
}

.loader {
  width: 30px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side,#ffffff 90%,#fdfdfd00);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: l7 1s infinite linear;
  margin-bottom: -10px;
  margin-left: 5px;
  
}

@keyframes l7 {
    33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
    50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
    66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%}
}

.slide-right:hover{
  transition: all .5s ease;
  transform: translateX(5px);
}



.mobile-navbar{
  transition: show 2s ease-in-out;
  display:flex;
  justify-Content:center;
  align-Items:center;
  position: absolute; 
  background: white;
  width: 100%;
  z-index: 1;
  opacity: 0;
}
.mobile-navbar ul{
  transition: transform 3s ease-in-out;
  text-transform:uppercase;
  padding:0;
  display:flex;
  flex-direction:column;
  list-Style-Type:none;
  justify-Content:center;
  align-Items:center;
  gap:20px;
  font-weight: 500;
}
.hide-menu{
  animation: hide 1s !important;
  opacity: 0;
}
.display{
  animation: show 1s;
  opacity: 1;
}
@keyframes show {
  0% {transform: translateY(-100%);opacity: 0;}
  100% {transform: translateY(0%);opacity:1}
}
@keyframes hide {
  0% {transform: translateY(0%);opacity: 1;}
 100% {transform: translateY(-100%);opacity: 0;}
}


@media only screen and (max-width: 768px){
.Desktop{
  display: none;
}
.Mobile{
  display: Block;
}
h1{
  font-size: 20px !important;
}
h3{
  font-size: 15px !important;
}
.column{
  align-items: center !important;
  flex-direction: column !important;
}
/* .column img{
  width: 60% !important;
} */
.category{
  display: block !important;;
  /* grid-template-columns: auto !important; */
  width: 100% !important;
}
.mobile{
  display: block !important;
  /* grid-template-columns: auto !important; */
  width: 100% !important;
}
.m0{
  margin: 0 !important;
}
.w100{
  width: 95% !important;
}

.procured div:nth-of-type(1){
grid-area: item1;
}
.procured div:nth-of-type(2){
grid-area: item2;
flex-direction: column-reverse !important;
}
.procured div:nth-of-type(3){
grid-area: item3;
}
.procured{
  grid-template-columns: 1fr 1fr !important;
  width: 100% !important;
  grid-template-areas:
  'item2 item2' 'item1 item3''item1 item3'

}
.position{
  position: static !important;
  transform:translate(0,0) !important;
  margin-left: 0 !important
}
.clients{
grid-template-columns: 1fr 1fr !important;
}
.img1{
  object-fit: contain !important;
  height: auto !important;
}
.reverse{
  display: flex;
  flex-direction: column-reverse !important;
}
.leaves{
  background-position: right !important;
}
.contact{
  /* background: black !important; */
  margin-top: 20px !important;
  /* color:white; */
}
.blogs{
  margin: 6% !important;
}
.p0{
padding: 0!important;
}
.bid{
  margin:10px !important;
}
.header-h1{
  line-height: normal;
}
.header-box{
  flex-direction: column;
}
.header-box div:nth-of-type(1){
  width: auto !important;
}
.btn{
  margin-left: 0 !important;
}
}


.slider-container {
  width: 100%;
  height: 33.3%; /* Adjust height as needed */
  overflow: hidden;
  position: relative;
  
}

.slider-content {
  display: flex;
  flex-direction: column; /* Change to column for vertical slides */
  width: 100%;
  height: 300%; /* Adjust height to match the number of slides */
  /* transition: transform 0.5s ease-in-out; */
  overflow: hidden; 
}

.slide {
  width: 100%;
  height: 100vh; /* Adjust height to match the number of slides */
  background-size: cover;
  background-position: center;
  position: relative;
}

.slide.active {
  opacity: 1;
}

.slide-caption {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.slider-controls {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.slider-controls button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px;
}


